@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	scroll-behavior: smooth;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 10px !important ;
	height: 10px !important ;
}
.custom-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #999;
}

.slim-custom-scrollbar::-webkit-scrollbar {
	width: 4px !important ;
}
.slim-custom-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.slim-custom-scrollbar::-webkit-scrollbar-thumb {
	background: #ccc;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
	width: 0 !important;
	height: 0 !important;
}

:root {
	--toastify-toast-min-height: 45px !important;
	--toastify-toast-max-height: 55px;
	--toastify-font-family: 'Rubik', 'Roboto', sans-serif !important;
	--primary: #273679;
}

body,
html {
	margin: 0;
	font-family: 'Rubik', 'Roboto', sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
}

#root {
	height: 100%;
	background: #f4f8f9;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.katex-html {
	display: none;
}

a {
	text-decoration: none !important;
}
.discussion-wysiwyg-container pre {
	background: #eee6;
	padding: 10px;
	border-radius: 5px;
	color: inherit;
}

.discussion-wysiwyg-container blockquote {
	border: inherit;
	border-left: 5px solid #aaa;
	background: #eee6;
	border-radius: 5px;
	margin: 10px 0;
	padding: 20px 40px;
}

.discussion-wysiwyg-container p {
	margin: 5px 0;
	font-size: 15px;
	text-align: justify;
}
.discussion-wysiwyg-container * {
	color: inherit !important;
}

.discussion-wysiwyg-container span {
	color: inherit !important;
	background: none !important;
	background-color: none !important;
}

.discussion-wysiwyg-container .ql-size-large {
	font-size: 20px;
}

.discussion-wysiwyg-container a {
	text-decoration: underline !important;
}

.discussion-wysiwyg-container .ql-size-small {
	font-size: 12px;
}

.discussion-wysiwyg-container img {
	max-width: 100%;
}

.discussion-wysiwyg-container h1,
.discussion-wysiwyg-container h2,
.discussion-wysiwyg-container h3,
.discussion-wysiwyg-container h4,
.discussion-wysiwyg-container h5,
.discussion-wysiwyg-container h6 {
	margin: 5px 0;
}

#embedd-bbb {
	height: 90vh;
	width: 100%;
	border: none;
}
#embedd-bbb button #tippy-3 {
	display: none !important;
}
.questionNbr {
	display: 'flex';
	padding: '10px';
	width: '30px';
	height: '30px';
	min-width: '30px';
	min-height: '30px';
	align-items: 'center';
	justify-content: 'center';
	border-radius: '50%';
	background: '#aaa';
	font-size: '12px';
	font-weight: 'bold';
}

.answered {
	position: 'relative';
	background: 'green';
	color: 'white';
}

@media (max-width: 900px) {
	.answered {
		background: 'none';
		color: 'black';
		border: '2px solid green';
	}
}

.default-thumb-preview {
	cursor: pointer;
	border: 2px solid grey;
	border-radius: 5px;
}

.default-thumb-preview.selected-preview-img {
	border: 5px solid #f18f08;
}

.notAnswered {
	position: 'relative';
	background: '#ed3447';
	color: 'white';
}

.breadcrumb-link {
	text-decoration: underline !important;
	color: inherit !important;
}

@media (max-width: 900px) {
	.notAnswered {
		background: 'none';
		color: 'black';
		border: '2px solid #ed3447';
	}
}

.thumbnail-input {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.default-link {
	color: inherit;
	text-decoration: none;
}

.custom-quill {
	height: calc(100% - 50px);
	width: 100%;
}

.full-screen-element {
	background: #f7f7fa;
	height: calc(100% -40px);
	padding: 20px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex-grow: 1;
}
.full-screen-element.light {
	background: #f7f7fa;
}
.full-screen-element.dark {
	background: #000;
}

.template-builder-full-screen {
	background: #f4f8f9;
	padding: 20px;
	height: 100%;
}

#calendar-view {
	margin-left: 3px;
}
.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none !important;
}

.react-calendar__month-view__days__day {
	width: 38px !important;
	height: 38px !important;
	border-radius: 50% !important;
	padding: 5px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.blink {
	animation: blink 1s ease infinite forwards;
}

.react-calendar__month-view__days__day abbr {
	padding: 10px;
	display: block;
	width: 38px;
	height: 38px;
	border-radius: 50%;
}

.react-calendar__month-view__days__day:hover {
	background: none !important;
}

.react-calendar__navigation__label {
	background-color: #eee;
	font-weight: bold;
}

.react-calendar__tile--now {
	background: #fff !important;
	color: black;
	border-radius: 50%;
}

.react-calendar__month-view__days__day:hover abbr {
	background: #eee !important;
}

.react-calendar__tile--now abbr {
	background: #273679;
	color: white;
}

.react-calendar__tile--now:hover abbr {
	background: #273679 !important;
	color: white !important;
}

.react-calendar__tile--active {
	background: none !important;
	color: black !important;
}

.faq-step-box {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 5px;
	padding: 5px;
}

.faq-step-box em,
.faq-em {
	font-size: 18px;
	font-weight: 600;
}

.faq-step-box a {
	font-style: italic;
	font-weight: 600;
	color: #555;
	text-decoration: underline !important;
}

.faq-answer-header {
	margin: 0;
	margin-left: 20px;
	color: #273679;
}

.faq-step-box a:hover {
	color: #aaa;
}

.faq-step-box p {
	font-weight: 400;
}

.faq-step {
	z-index: 2;
	padding: 5px;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: #aaa 1px solid;
}

.ql-editing {
	z-index: 13000;
	left: 100px !important;
	top: 10px !important;
	background: white;
}

.mq-editable-field {
	border-radius: 5px !important;
}

.mq-editable-field.mq-focused {
	box-shadow: none !important;
	border-color: #273679 !important;
	border-width: 2px;
}

.mq-math-mode,
.mq-math-mode .mq-editable-field {
	padding: 15px 10px;
	font-size: 16px !important;
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-style: normal !important;
}

.mq-math-mode var,
.mq-math-mode i,
.mq-math-mode i.mq-font {
	font-style: normal !important;
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-weight: 400 !important;
	font-size: 1em !important;
}

.mq-editable-field {
	display: -moz-inline-box;
	display: inline-block;
}
.mq-editable-field .mq-cursor {
	border-left: 1px solid black;
	margin-left: -1px;
	position: relative;
	z-index: 1;
	padding: 0;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-editable-field .mq-cursor.mq-blink {
	visibility: hidden;
}
.mq-editable-field,
.mq-math-mode .mq-editable-field {
	border: 1px solid gray;
}
.mq-editable-field.mq-focused,
.mq-math-mode .mq-editable-field.mq-focused {
	-webkit-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	-moz-box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	box-shadow: #8bd 0 0 1px 2px, inset #6ae 0 0 2px 0;
	border-color: #709ac0;
	border-radius: 1px;
}
.mq-math-mode .mq-editable-field {
	margin: 1px;
}
.mq-editable-field .mq-latex-command-input {
	color: inherit;
	font-family: 'Courier New', monospace;
	border: 1px solid gray;
	padding-right: 1px;
	margin-right: 1px;
	margin-left: 2px;
}
.mq-editable-field .mq-latex-command-input.mq-empty {
	background: transparent;
}
.mq-editable-field .mq-latex-command-input.mq-hasCursor {
	border-color: ActiveBorder;
}
.mq-editable-field.mq-empty:after,
.mq-editable-field.mq-text-mode:after,
.mq-math-mode .mq-empty:after {
	visibility: hidden;
	content: 'c';
}
.mq-editable-field .mq-cursor:only-child:after,
.mq-editable-field .mq-textarea + .mq-cursor:last-child:after {
	visibility: hidden;
	content: 'c';
}
.mq-editable-field .mq-text-mode .mq-cursor:only-child:after {
	content: '';
}
.mq-editable-field.mq-text-mode {
	overflow-x: auto;
	overflow-y: hidden;
}
.mq-root-block,
.mq-math-mode .mq-root-block {
	display: -moz-inline-box;
	display: inline-block;
	width: 100%;
	padding: 2px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
}
.mq-math-mode {
	font-variant: normal;
	font-weight: normal;
	font-style: normal;
	font-size: 115%;
	line-height: 1;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-non-leaf,
.mq-math-mode .mq-scaled {
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode var,
.mq-math-mode .mq-text-mode,
.mq-math-mode .mq-nonSymbola {
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-size: 14px;
	line-height: 0.9;
}
.mq-math-mode * {
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
	border-color: black;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	box-sizing: border-box;
}
.mq-math-mode .mq-empty {
	background: #ccc;
}
.mq-math-mode .mq-empty.mq-root-block {
	background: transparent;
}
.mq-math-mode.mq-empty {
	background: transparent;
}
.mq-math-mode .mq-text-mode {
	font-size: 87%;
}
.mq-math-mode .mq-font {
	font-family: 'Rubik', 'Roboto', sans-serif !important;
}
.mq-math-mode .mq-font * {
	font-family: inherit;
	font-style: inherit;
}
.mq-math-mode b,
.mq-math-mode b.mq-font {
	font-weight: 400;
}
.mq-math-mode var,
.mq-math-mode i,
.mq-math-mode i.mq-font {
	font-style: normal;
}
.mq-math-mode var.mq-f {
	margin-right: 0.2em;
	margin-left: 0.1em;
}
.mq-math-mode .mq-roman var.mq-f {
	margin: 0;
}
.mq-math-mode big {
	font-size: 125%;
}
.mq-math-mode .mq-roman {
	font-style: normal;
}
.mq-math-mode .mq-sans-serif {
	font-family: sans-serif, Symbola, serif;
}
.mq-math-mode .mq-monospace {
	font-family: monospace, Symbola, serif;
}
.mq-math-mode .mq-overline {
	border-top: 1px solid black;
	margin-top: 1px;
}
.mq-math-mode .mq-underline {
	border-bottom: 1px solid black;
	margin-bottom: 1px;
}
.mq-math-mode .mq-binary-operator {
	padding: 0 0.2em;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-supsub {
	font-size: 90%;
	vertical-align: -0.5em;
}
.mq-math-mode .mq-supsub.mq-limit {
	font-size: 80%;
	vertical-align: -0.4em;
}
.mq-math-mode .mq-supsub.mq-sup-only {
	vertical-align: 0.5em;
}
.mq-math-mode .mq-supsub.mq-sup-only .mq-sup {
	display: inline-block;
	vertical-align: text-bottom;
}
.mq-math-mode .mq-supsub .mq-sup {
	display: block;
}
.mq-math-mode .mq-supsub .mq-sub {
	display: block;
	float: left;
}
.mq-math-mode .mq-supsub.mq-limit .mq-sub {
	margin-left: -0.25em;
}
.mq-math-mode .mq-supsub .mq-binary-operator {
	padding: 0 0.1em;
}
.mq-math-mode .mq-supsub .mq-fraction {
	font-size: 70%;
}
.mq-math-mode sup.mq-nthroot {
	font-size: 80%;
	vertical-align: 0.8em;
	margin-right: -0.6em;
	margin-left: 0.2em;
	min-width: 0.5em;
}
.mq-math-mode .mq-paren {
	padding: 0 0.1em;
	vertical-align: top;
	-webkit-transform-origin: center 0.06em;
	-moz-transform-origin: center 0.06em;
	-ms-transform-origin: center 0.06em;
	-o-transform-origin: center 0.06em;
	transform-origin: center 0.06em;
}
.mq-math-mode .mq-paren.mq-ghost {
	color: silver;
}
.mq-math-mode .mq-paren + span {
	margin-top: 0.1em;
	margin-bottom: 0.1em;
}
.mq-math-mode .mq-array {
	vertical-align: middle;
	text-align: center;
}
.mq-math-mode .mq-array > span {
	display: block;
}
.mq-math-mode .mq-operator-name {
	font-family: Symbola, 'Times New Roman', serif;
	line-height: 0.9;
	font-style: normal;
}
.mq-math-mode var.mq-operator-name.mq-first {
	padding-left: 0.2em;
}
.mq-math-mode var.mq-operator-name.mq-last {
	padding-right: 0.2em;
}
.mq-math-mode .mq-fraction {
	font-size: 90%;
	text-align: center;
	vertical-align: -0.4em;
	padding: 0 0.2em;
}
.mq-math-mode .mq-fraction,
.mq-math-mode .mq-large-operator,
.mq-math-mode x:-moz-any-link {
	display: -moz-groupbox;
}
.mq-math-mode .mq-fraction,
.mq-math-mode .mq-large-operator,
.mq-math-mode x:-moz-any-link,
.mq-math-mode x:default {
	display: inline-block;
}
.mq-math-mode .mq-numerator,
.mq-math-mode .mq-denominator {
	display: block;
}
.mq-math-mode .mq-numerator {
	padding: 0 0.1em;
}
.mq-math-mode .mq-denominator {
	border-top: 1px solid;
	float: right;
	width: 100%;
	padding: 0.1em;
}
.mq-math-mode .mq-sqrt-prefix {
	padding-top: 0;
	position: relative;
	top: 0.1em;
	vertical-align: top;
	-webkit-transform-origin: top;
	-moz-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
}
.mq-math-mode .mq-sqrt-stem {
	border-top: 1px solid;
	margin-top: 1px;
	padding-left: 0.15em;
	padding-right: 0.2em;
	margin-right: 0.1em;
	padding-top: 1px;
}
.mq-math-mode .mq-vector-prefix {
	display: block;
	text-align: center;
	line-height: 0.25em;
	margin-bottom: -0.1em;
	font-size: 0.75em;
}
.mq-math-mode .mq-vector-stem {
	display: block;
}
.mq-math-mode .mq-large-operator {
	text-align: center;
}
.mq-math-mode .mq-large-operator .mq-from,
.mq-math-mode .mq-large-operator big,
.mq-math-mode .mq-large-operator .mq-to {
	display: block;
}
.mq-math-mode .mq-large-operator .mq-from,
.mq-math-mode .mq-large-operator .mq-to {
	font-size: 80%;
}
.mq-math-mode .mq-large-operator .mq-from {
	float: right;
	/* take out of normal flow to manipulate baseline */
	width: 100%;
}
.mq-math-mode,
.mq-math-mode .mq-editable-field {
	cursor: text;
	font-family: Symbola, 'Times New Roman', serif;
}
.mq-math-mode .mq-overarrow {
	border-top: 1px solid black;
	margin-top: 1px;
	padding-top: 0.2em;
}
.mq-math-mode .mq-overarrow:before {
	display: block;
	position: relative;
	top: -0.34em;
	font-size: 0.5em;
	line-height: 0em;
	content: '\27A4';
	text-align: right;
}
.mq-math-mode .mq-overarrow.mq-arrow-left:before {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: 'FlipH';
}
.mq-math-mode .mq-selection,
.mq-editable-field .mq-selection,
.mq-math-mode .mq-selection .mq-non-leaf,
.mq-editable-field .mq-selection .mq-non-leaf,
.mq-math-mode .mq-selection .mq-scaled,
.mq-editable-field .mq-selection .mq-scaled {
	background: #b4d5fe !important;
	background: Highlight !important;
	color: HighlightText;
	border-color: HighlightText;
}
.mq-math-mode .mq-selection .mq-matrixed,
.mq-editable-field .mq-selection .mq-matrixed {
	background: #39f !important;
}
.mq-math-mode .mq-selection .mq-matrixed-container,
.mq-editable-field .mq-selection .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='#3399FF') !important;
}
.mq-math-mode .mq-selection.mq-blur,
.mq-editable-field .mq-selection.mq-blur,
.mq-math-mode .mq-selection.mq-blur .mq-non-leaf,
.mq-editable-field .mq-selection.mq-blur .mq-non-leaf,
.mq-math-mode .mq-selection.mq-blur .mq-scaled,
.mq-editable-field .mq-selection.mq-blur .mq-scaled,
.mq-math-mode .mq-selection.mq-blur .mq-matrixed,
.mq-editable-field .mq-selection.mq-blur .mq-matrixed {
	background: #d4d4d4 !important;
	color: black;
	border-color: black;
}
.mq-math-mode .mq-selection.mq-blur .mq-matrixed-container,
.mq-editable-field .mq-selection.mq-blur .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='#D4D4D4') !important;
}
.mq-editable-field .mq-textarea,
.mq-math-mode .mq-textarea {
	position: relative;
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
}
.mq-editable-field .mq-textarea *,
.mq-math-mode .mq-textarea *,
.mq-editable-field .mq-selectable,
.mq-math-mode .mq-selectable {
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
	position: absolute;
	clip: rect(1em 1em 1em 1em);
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	resize: none;
	width: 1px;
	height: 1px;
}
.mq-math-mode .mq-matrixed {
	background: white;
	display: -moz-inline-box;
	display: inline-block;
}
.mq-math-mode .mq-matrixed-container {
	filter: progid:DXImageTransform.Microsoft.Chroma(color='white');
	margin-top: -0.1em;
}

.static-math-mode {
	padding: 0 !important;
}

#static-latex {
	border: none !important;
	outline: none !important;
	pointer-events: none !important;
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-style: normal !important;
}

#static-latex .mq-root-block > var,
#static-latex .mq-root-block > span {
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-size: 14px !important;
}

#answer-section-latex {
	border: none !important;
	outline: none !important;
	pointer-events: none !important;
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-style: normal !important;
}

#answer-section-latex .mq-root-block > var,
#answer-section-latex .mq-root-block > span {
	font-family: 'Rubik', 'Roboto', sans-serif !important;
	font-size: 18px !important;
}

.bg-pattern {
	background-image: url('../../public/imgs/bg-pattern1.png');
	background-repeat: repeat;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
