video {
	background: #000;
}

.video-title {
	position: absolute;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
	top: 0;
	left: 0;
	right: 0;
	bottom: 85%;
	color: #fff;
	padding: 10px 20px;
	opacity: 0;
	transition: opacity 1s ease;
}

.video-controls-container:not(.paused) {
	transition: opacity 1s ease;
	opacity: 0;
}

.video-container {
	position: relative;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	margin-inline: auto;
	background: #4f4f4f;
	user-select: none;
}

.video-container .video-player {
	width: 100%;
	height: 100%;
}

.video-controls-container {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
	cursor: pointer;
}

.paused,
.show-overlay,
/* .video-controls-container:hover, */
/* .video-container:hover .video-controls-container, */
.video-container:hover .play-pause-area,
.video-container:hover .video-title {
	transition: opacity 1s ease !important;
	opacity: 1 !important;
	color: white !important;
}

.video-container.fullscreen {
	height: 100vh;
	width: 100%;
}

.video-container.fullscreen .video-player {
	height: 100%;
	width: 100%;
}

.control-btn {
	padding: 0;
	color: white;
	background: none;
	border: none;
	cursor: pointer;
	transition: transform 0.1s ease;
}

.play-pause-btn {
	padding: 0;
	color: white;
	background: none;
	border: none;
	transition: transform 0.1s ease;
}

.control-btn:hover {
	transform: scale(1.05);
}

.volume-slider {
	width: 0;
	height: 1px;
	opacity: 0;
	transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
	appearance: none;
}

.volume-slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 15px;
	height: 15px;
	background: white;
	border-radius: 50%;
}
.volume-container:hover .volume-slider {
	width: 80px;
	opacity: 1;
}
.timeline-container {
	height: 7px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	margin-inline: 8px;
}

.timeline-container.scrubbing .timeline,
.timeline-container:hover .timeline {
	height: 100%;
}

.timeline {
	height: 3px;
	background: rgb(150, 150, 150);
	width: 100%;
	position: relative;
}

.timeline::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--preview-position) * 100%);
	background: #eee;
	display: none;
}

.timeline::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--progress-position) * 100%);
	background: #f18f08;
}

.buffer-loaded {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: calc(100% - var(--buffer-position) * 100%);
	background: rgba(245, 245, 245, 0.7);
}

.timeline-container.scrubbing .timeline::before,
.timeline-container:hover .timeline::before {
	display: block;
}

.thumb-indicator {
	--scale: 0;
	position: absolute;
	transform: translateX(-50%) scale(var(--scale));
	height: 200%;
	top: -50%;
	left: calc(var(--progress-position) * 100%);
	background: #f18f08;
	border-radius: 50%;
	transition: transform 150ms ease-in-out;
	aspect-ratio: 1/1;
	z-index: 10;
}

.timeline-container.scrubbing .thumb-indicator,
.timeline-container:hover .thumb-indicator {
	--scale: 1;
}

@media (max-width: 900px) {
	.video-player {
		width: 100%;
	}
}
