*.editable-element {
	outline: none;
	margin: 0;
}

.editable-border-lg,
.editable-border-sm {
	position: relative;
	border-bottom: 1px solid #d3d3d3;
}

.editable-border-sm::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 1px;
	background: #d3d3d3;
	transition: width 100ms 0s;
}

.editable-border-lg::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 2px;
	background: var(--primary);
	transition: width 100ms 0s;
}

.editable-border-lg:focus::after,
.editable-border-sm:focus::after {
	width: 100%;
}

.editable-section {
	border-left: 5px solid white;
}

.editable-section:focus-within {
	border-left: 5px solid var(--primary);
}

.editable-parent {
	position: relative;
}

.editable-placeholder {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
}

.dragging-over {
	border: 2px dotted grey;
}

.dragging-element {
	opacity: 0.01;
}

.draggable-element {
	user-select: none;
}

.draggable-element.drag-over {
	border-top: 3px solid #d3d3d3;
	/* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19); */
}

.draggable-element.dragging {
	opacity: 0.001;
	cursor: grabbing;
}
