.drag {
	border-color: #273679 !important;
	background: #8db4d745;
}

.drag img {
	animation: shake 650ms infinite;
	pointer-events: none;
}

@keyframes shake {
	0% {
		transform: rotate(0deg) scale(1.1);
	}
	25% {
		transform: rotate(5deg) scale(1.1);
	}
	50% {
		transform: rotate(0eg) scale(1.1);
	}
	75% {
		transform: rotate(-5deg) scale(1.1);
	}
	100% {
		transform: rotate(0deg) scale(1.1);
	}
}
